import React, { useState } from 'react';
import ErrorHandling from './ErrorHandling';

function ScytaleCipher() {
    const [message, setMessage] = useState('');
    const [columns, setColumns] = useState(0);
    const [result, setResult] = useState('');
    const [error, setError] = useState('');

    const handleEncrypt = () => {
        if (!message) {
            setError('Por favor, ingrese un mensaje y el número correcto de columnas.');
            return;
        }
        if (columns >= message.length ){
            setError('Por favor, un número de columnas que sea menor al número de caracteres en su mensaje.');
            return;
        }
        setError('');

        let encrypted = '';
        const cleanMessage = message.replace(/\s+/g, ''); // Quitamos los espacios del mensaje original

        for (let i = 0; i < columns; i++) {
            for (let j = i; j < cleanMessage.length; j += columns) {
                encrypted += cleanMessage[j];
            }
        }

        setResult(encrypted); // Mostramos el resultado cifrado
    };

    const handleDecrypt = () => {
        if (!message || columns <= 0) {
            setError('Por favor, ingrese un mensaje y/o el número correcto de columnas.');
            return;
        }
        setError('');

        let decrypted = new Array(message.length).fill('');
        let position = 0;

        // Recorremos el mensaje para organizarlo por filas y columnas
        for (let i = 0; i < columns; i++) {
            for (let j = i; j < message.length && position < message.length; j += columns) {
                decrypted[j] = message[position];
                position++;
            }
        }

        setResult(decrypted.join('')); // Unimos las letras y mostramos el resultado descifrado
    };

    return (
        <div className="cipher-container">
            <div className='info-cipher'>
                <h2>Cifrado Escítala</h2>

                <p>El Cifrado Escítala es un método de cifrado por transposición utilizado por los antiguos griegos. A diferencia del Cifrado César, que cambia las letras por otras, el Cifrado Escítala reorganiza las letras del mensaje según una disposición en forma de columnas.</p>

                <p>Funcionamiento:</p>
                <ul>
                    <li>Se elige un número de columnas para organizar el mensaje.</li>
                    <li>El mensaje se escribe en filas, organizando las letras en una tabla de un número determinado de columnas.</li>
                    <li>Para cifrar, se lee el mensaje en columna, de arriba a abajo.</li>
                    <li>Para descifrar, se vuelve a organizar el texto en filas utilizando el mismo número de columnas.</li>
                </ul>
                <p>Ejemplo:</p>
                <ul>
                    <li>Mensaje original: "DOCENTE"</li>
                    <li>
                        El mensaje se organiza en una tabla de 3 columnas:
                        <table border="1" className='tbl'>
                            <tr>
                                <td>D</td>
                                <td>O</td>
                                <td>C</td>
                            </tr>
                            <tr>
                                <td>E</td>
                                <td>N</td>
                                <td>T</td>
                            </tr>
                            <tr>
                                <td>E</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </li>
                    <li>
                        Luego, se lee el mensaje por columnas:
                        {" D -> E -> E -> O -> N -> C -> T"}
                    </li>
                    <li>
                        Cifrado: "DEEONCT"
                    </li>
                </ul>
            </div>
            <div className='info-cipher'>
                <h2>Try it Out!</h2>
                <ErrorHandling error={error} /> {/* Componente para mostrar los errores */}
                <div className="grid-container">
                    <label>
                        Mensaje:
                    </label>
                    <input
                        type="text"
                        placeholder="Mensaje"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <label>
                        Desplazamiento:
                    </label>
                    <input
                        type="number"
                        placeholder="Número de columnas"
                        value={columns}
                        onChange={(e) => setColumns(Number(e.target.value))}
                    />
                </div>
                <div>
                    <button onClick={handleEncrypt}>Cifrar</button>
                    <button onClick={handleDecrypt}>Descifrar</button>
                </div>
                <p>Resultado: {result}</p>
            </div>
        </div>
    );
}

export default ScytaleCipher;
